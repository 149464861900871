import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="leadkee--offer"
export default class extends Controller {
  static targets = ["new_comment_form", "first_comment", "no_comment_text", "reply_comment_btn"]

  show_comment(event) {
    event.preventDefault()
    this.first_commentTarget.classList.add("hidden")
    this.no_comment_textTarget.classList.add("hidden")
    this.new_comment_formTarget.classList.remove("hidden")
  }

  submit_comment(event) {
    this.element.form.requestSubmit()
  }

  show_reply(event) {
    event.preventDefault()
    this.reply_comment_btnTarget.classList.add("hidden")
    this.new_comment_formTarget.classList.remove("hidden")
    this.new_comment_formTarget.classList.add("mt-4")
  }
}
